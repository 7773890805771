.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pd {
  padding: 20px;
}

.main-layout {
  min-height: 100vh;
}

.main-content {
  width: 1000px;
  margin: auto;
}

.main-header {
  height: 175px;
  background-image: url('../public/img/banner-main.jpg');
  background-position: center;
}

.main-navbar {
  display: none;
}

.footer {
  border-top: solid lightgray 1px;
  background-image: url('../public/img/banner-main.jpg');
  background-position: bottom;
  color: gray;
}

.main-content .rs-steps-item-status-process .rs-steps-item-icon-wrapper {
  border-color: lightgrey;
  background-color: lightgrey;
  color: #575757;
}

h3 {
  padding-bottom: 25px;
}

.header-title {
  color: lightgray;
  line-height: 175px;
  text-align: center;
  font-size: 40px;
  font-weight: bold;
}